




































































































































import { Component, Watch, Prop } from "vue-property-decorator";
import BaseComponent from "../components/BaseComponent";
import ErrorComponent from "../components/ErrorComponent.vue";
import DatePickerComponent from "../components/DatePickerComponent.vue";
import FormattedTextBoxComponent from "../components/FormattedTextBoxComponent.vue";
import DialogComponent from "../components/DialogComponent.vue";
import DeleteModalComponent from "../components/DeleteModalComponent.vue";
import ValidationSummaryComponent from "../components/ValidationSummaryComponent.vue";
import { getExpense, createExpense, updateExpense, deleteExpense, getVendor } from "../store/rest.service";
import { Expense, CurrentUser, CommonMeta, Vendor } from "../store/models";
import { EmptyExpense } from "../store/models-empty";
import ExpenseHelper from "../components/ExpenseHelper";
import { handleError } from "../lib/shared";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import { rules } from "../lib/validation";

import * as _ from "lodash";

@Component({
  components: {
    ErrorComponent,
    ValidationSummaryComponent,
    SpinnerComponent,
    DatePickerComponent,
    FormattedTextBoxComponent,
    DialogComponent,
    DeleteModalComponent,
  },
})
export default class ExpenseComponent extends BaseComponent {
  @Prop() private expenseId: any;
  @Prop() private projectId: any;
  @Prop() private payeeId: any;

  private helper: ExpenseHelper = new ExpenseHelper();
  private helperLoading: boolean = true;

  private expenseSaving: boolean = false;

  private editedItem: Expense = _.cloneDeep(EmptyExpense);
  private editedExpenseId: string = "";
  private expenseLoading: boolean = false;

  private bDeleteExpenseModal: boolean = false;
  private deleteExpenseId: string = "";
  private deleteExpenseName: string = "";
  private deleteExpenseEtag: string = "";

  private arrErrors: Error[] = [];
  private extraErrors: Array<[string, string]> = [];
  private updateValidation: number = 0;
  private ownedByIc: string = "";

  private items: string[] = ["Testing"];

  protected mounted() {
    if (this.expenseId !== "") {
      this.editExpense(this.expenseId);
    } else {
      this.initExpense();
    }
  }

  @Watch("helper.isLoading")
  private onHelperChanged(val: any, oldVal: any) {
    this.helperLoading = this.helper.isLoading;
    this.changePayee(true);
  }

  @Watch("editedItem.expense.expenseClass")
  private onExpenseClassChanged(newClass: string) {
    if (newClass === "contribution") {
      this.editedItem.expense.expenseKind = "";
    }
  }

  private isLoading(): boolean {
    return this.expenseLoading || this.helperLoading;
  }

  private isExpenseClassDisabled(): boolean {
    return this.editedItem.payment.hasPayee === "";
  }

  private isExpenseTypeDisabled(): boolean {
    return (this.editedItem.payment.hasProject && this.editedItem.payment.hasProject !== "") || this.editedItem.expense.expenseClass === "";
  }

  private isExpenseKindDisabled(): boolean {
    return this.editedItem.payment.hasPayee === "";
  }

  private isProjectRequired(): boolean {
    return this.editedItem.expense.expenseType.startsWith("project-");
  }

  private changePayee(bInitialLoad: boolean = false) {
    const payeeId: string = this.editedItem.payment.hasPayee ? (this.editedItem.payment.hasPayee as string) : "";
    if (payeeId !== "") {
      getVendor(payeeId)
        .then((response) => {
          const vendor: Vendor = response as Vendor;
          this.ownedByIc = vendor.vendor.proxyPayeeFor ? (vendor.vendor.proxyPayeeFor as string) : "";
          if (this.editedExpenseId === "" || !bInitialLoad) {
            this.getDefaultExpenseClass();
          }
        })
        .catch((error) => {
          handleError(this.arrErrors, error);
        });
    }
  }

  private getDefaultExpenseClass() {
    if (this.helper.vendors) {
      const vendor = this.helper.vendors.find((v) => v.identifier === this.editedItem.payment.hasPayee);
      if (vendor !== undefined && vendor.defaultExpenseClass) {
        if (vendor.defaultExpenseClass !== "unknown") {
          this.editedItem.expense.expenseClass = vendor.defaultExpenseClass;
        }
        this.getExpenseTypes();
      }
    }
  }

  private getExpenseTypes() {
    if (this.editedItem.expense.expenseClass !== "") {
      this.helper.getExpenseTypesFiltered(this.editedItem.expense.expenseClass, this.ownedByIc === "");
      if (!this.editedItem.common || (this.editedItem.common as CommonMeta).identifier !== "") {
        if (this.editedItem.payment.hasProject && this.editedItem.payment.hasProject !== "") {
          this.editedItem.expense.expenseType = this.editedItem.expense.expenseClass === "contribution" ? "project-contribution" : "project-expense";
        }
      }
    }
  }

  private updateEditedExpense(id: string) {
    this.editedExpenseId = id;
    this.getExpense(this.editedExpenseId);
    this.helper.populateDataLists(this.currentOrg);
  }

  private editExpense(id: string) {
    this.updateEditedExpense(id);
  }

  private initExpense() {
    this.helper.populateDataLists(this.currentOrg);
    this.editedItem = _.cloneDeep(EmptyExpense);
    this.editedItem.expense.expenseKind = "cash";
    if (this.projectId !== "") {
      this.editedItem.payment.hasProject = this.projectId;
    }
    if (this.payeeId !== "") {
      this.editedItem.payment.hasPayee = this.payeeId;
    }
  }

  private closeExpense() {
    this.$emit("close");
  }

  private closeDelete() {
    this.bDeleteExpenseModal = false;
    this.$emit("delete");
  }

  private voidExpense() {
    this.deleteExpenseEtag = (this.editedItem.common as CommonMeta).etag as string;
    this.deleteExpenseId = (this.editedItem.common as CommonMeta).identifier as string;
    this.bDeleteExpenseModal = true;
  }

  private performDeleteExpense() {
    deleteExpense(this.deleteExpenseId, this.deleteExpenseEtag)
      .then((response) => {
        this.closeDelete();
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      });
  }

  private save() {
    if (!this.expenseSaving) {
      if ((this.$refs.form1 as any).validate()) {
        this.updateValidation++;

        this.editedExpenseId === "" ? this.createExpense() : this.updateExpense();
      } else {
        this.updateValidation++;
      }
    }
  }

  private getExpense(id: string) {
    this.expenseLoading = true;
    getExpense(id)
      .then((response) => {
        this.editedItem = response;
        this.getExpenseTypes();
      })
      .catch((error) => {
        this.$emit("error", error);
      })
      .finally(() => {
        this.expenseLoading = false;
      });
  }

  private isCurrentVendorIndigenous() {
    return this.editedItem.payment.hasPayee && this.helper.isIndigenousVendor(this.helper.getPayee(this.editedItem.payment.hasPayee) as Vendor);
  }

  private getExpenseToSave(expense: Expense): Expense {
    expense.revenue.revenueClass = expense.expense.expenseClass;
    expense.revenue.revenueType = this.helper.getRevenueType(expense.expense.expenseType);
    expense.revenue.revenueKind = expense.expense.expenseKind;
    expense.payment.hasPayer = this.$store.getters["local/CURRENT_ORG"];
    expense.payment.hasSubmitter = (this.$store.getters["session/CURRENT_USER"] as CurrentUser).userinfo.identifier;

    if (this.editedItem.payment.hasPayee) {
      if (this.isCurrentVendorIndigenous()) {
        if (expense.expense.expenseClass === "contribution") {
          if (expense.payment.hasProject) {
            expense.expense.expenseType = "project-contribution";
            expense.revenue.revenueType = "project-contribution-revenue";
          } else {
            expense.expense.expenseType = "community-contribution";
            expense.revenue.revenueType = "community-contribution-revenue";
          }
        } else {
          if (expense.payment.hasProject) {
            expense.expense.expenseType = "project-expense";
            expense.revenue.revenueType = "project-revenue";
          } else {
            expense.expense.expenseType = "general-expense";
            expense.revenue.revenueType = "general-revenue";
          }
          expense.expense.expenseKind = "cash";
          expense.revenue.revenueKind = "cash";
        }
      } else {
        if (expense.expense.expenseClass === "contribution") {
          if (expense.payment.hasProject) {
            expense.expense.expenseType = "project-contribution";
            expense.revenue.revenueType = "project-contribution-revenue";
          } else {
            expense.expense.expenseType = "donation-expense";
            expense.revenue.revenueType = "donation-revenue";
          }
        } else {
          if (expense.payment.hasProject) {
            expense.expense.expenseType = "project-expense";
            expense.revenue.revenueType = "project-revenue";
          } else {
            expense.expense.expenseType = "general-expense";
            expense.revenue.revenueType = "general-revenue";
          }
          expense.expense.expenseKind = "cash";
          expense.revenue.revenueKind = "cash";
        }
      }
    } else {
      expense.revenue.revenueType = expense.payment.hasProject ? (expense.expense.expenseClass === "contribution" ? "project-contribution-revenue" : "project-revenue") : "general-revenue";
    }
    return expense;
  }

  private createExpense() {
    this.expenseSaving = true;
    const expenseToSave = this.getExpenseToSave(_.cloneDeep(this.editedItem));
    createExpense(expenseToSave)
      .then((response) => {
        this.closeExpense();
        this.$emit("save", response);
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.expenseSaving = false;
      });
  }

  private updateExpense() {
    this.expenseSaving = true;
    const expenseToSave = this.removeCommonProperties(this.getExpenseToSave(_.cloneDeep(this.editedItem)));
    updateExpense(this.editedExpenseId, expenseToSave)
      .then((response) => {
        this.closeExpense();
        this.$emit("save", response);
      })
      .catch((error) => {
        handleError(this.arrErrors, error);
      })
      .finally(() => {
        this.expenseSaving = false;
      });
  }

  // The user must be a planner for the current organization to edit.
  private isEditable(): boolean {
    // return (this.editedExpenseId === '' || this.isPlannerForCurrentOrg());
    return true;
  }
}
