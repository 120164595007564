import { getShortList, getReferenceData, getVendor, getCategoryReferenceData, getVendorsViaRecordRequest } from '../store/rest.service';
import { ResourceSearchResult, ReferenceData, ShortRecord, Vendor, CategoryReferenceData, TieredReferenceData } from '../store/models';
import { handleError } from '../lib/shared';

export default class ExpenseHelper {

  public arrErrors: Error[] = [];
  public isLoading: boolean = true;

  public projects: ShortRecord[]|null = null;
  public projectsLoading: boolean = false;

  public vendors: ShortRecord[]|null = null;
  public vendorsLoading: boolean = false;

  public ics: ShortRecord[]|null = null;
  public icsLoading: boolean = false;

  public expenseClasses: ReferenceData|null = null;
  public expenseClassesLoading: boolean = false;

  public expenseTypes: ReferenceData|null = null;
  public expenseTypesLoading: boolean = false;

  public contributionKinds: ReferenceData|null = null;
  public contributionKindsLoading: boolean = true;

  public expenseTypesFiltered: ReferenceData|null = null;
  public expenseTypesFilteredLoading: boolean = false;

  public expenseKindsFiltered: ReferenceData|null = null;
  public expenseKindsFilteredLoading: boolean = false;

  public expenseKindsAll: ReferenceData|null = null;
  public expenseKindsAllLoading: boolean = false;

  public spendTypes: ReferenceData|null = null;
  public spendTypesLoading: boolean = false;

  public investmentTypes: TieredReferenceData|null = null;
  public investmentTypesLoading: boolean = false;
  public investmentTypesConverted: any = [];

  public setIsLoading(): void {
    this.isLoading = (
      this.vendorsLoading
      || this.icsLoading
      || this.projectsLoading
      || this.expenseClassesLoading
      || this.expenseTypesLoading
      || this.expenseKindsAllLoading
    );
  }

  public populateDataLists(currentOrg: string) {
    this.getProjects(currentOrg);
    this.getVendors(currentOrg);
    this.getIndigenousCommunities();
    this.getExpenseClasses();
    this.getExpenseKindsAll();
    this.getExpenseTypes();
    this.getContributionKinds();
  }

  public getSpendTypesProjectBenefits() {
    if (!this.spendTypes) {
      this.spendTypesLoading = true;
      getReferenceData('project-benefit-types')
      .then((response) => {
        this.spendTypes = response;
        getReferenceData('community-investment-types')
        .then((response2) => {
          for (const i of (response2 as ReferenceData).refData.values) {
            const items = (this.spendTypes as ReferenceData).refData.values;
            if (!items.filter((item) => item.code === i.code)) {
              (this.spendTypes as ReferenceData).refData.values.push(i);
            }
          }
        }).catch((error) => {
          handleError(this.arrErrors, error);
      });
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.spendTypesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getSpendTypesCommunityInvestments() {
    if (!this.spendTypes) {
      this.spendTypesLoading = true;
      getReferenceData('community-investment-types')
      .then((response) => {
        this.spendTypes = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.spendTypesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getContributionKinds() {
    if (!this.contributionKinds) {
      this.contributionKindsLoading = true;
      getReferenceData('contribution-kinds')
      .then((response) => {
        this.contributionKinds = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.contributionKindsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getExpenseClasses() {
    if (!this.expenseClasses) {
      this.expenseClassesLoading = true;
      getReferenceData('expenseClass')
      .then((response) => {
        this.expenseClasses = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.expenseClassesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getExpenseTypes() {
    if (!this.expenseTypes) {
      this.expenseTypesLoading = true;
      getReferenceData('expenseTypeAll')
      .then((response) => {
        this.expenseTypes = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.expenseTypesLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getExpenseTypesFiltered(expenseClass: string, filterOutCommunityContribution: boolean = false) {
    this.expenseTypesFilteredLoading = true;
    getReferenceData('expenseType-' + expenseClass)
    .then((response) => {
      this.expenseTypesFiltered = response;
      if (filterOutCommunityContribution) {
        this.expenseTypesFiltered.refData.values = this.expenseTypesFiltered.refData.values.filter((x) => x.code !== 'community-contribution');
      }
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.expenseTypesFilteredLoading = false;
      // this.setIsLoading();
    });
  }

  public getExpenseKindsFiltered(expenseClass: string) {
    this.expenseKindsFilteredLoading = true;
    getReferenceData('expenseKind-' + expenseClass)
    .then((response) => {
      this.expenseKindsFiltered = response;
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.expenseKindsFilteredLoading = false;
      // this.setIsLoading();
    });
  }

  public getExpenseKindsAll() {
    if (!this.expenseKindsAll) {
      this.expenseKindsAllLoading = true;
      getReferenceData('expenseKindAll')
      .then((response) => {
        this.expenseKindsAll = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.expenseKindsAllLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getVendors(currentOrg: string, force: boolean = false) {
    if (!this.vendors || force) {
      this.vendorsLoading = true;
      getVendorsViaRecordRequest().then((response) => {
        this.vendors = response;
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.vendorsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getIndigenousCommunities() {
    if (!this.ics) {
      this.icsLoading = true;
      getShortList('IndigenousCommunity')
      .then((response) => {
        this.ics = (response as ResourceSearchResult).searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.icsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public getProjects(currentOrg: string, force: boolean = false, includeDuplicateRecordProperty: boolean = true) {
    if (!this.projects || force) {
      this.projectsLoading = true;
      const whereClauses: Array<[string, string[]]> = [ [ 'project.ownerVendor', [ currentOrg ] ] ];
      getShortList('Project', false, whereClauses, false, includeDuplicateRecordProperty ? 'poId' : '')
      .then((response) => {
        this.projects = (response as ResourceSearchResult).searchResults.results as ShortRecord[];
      }).catch((error) => {
        handleError(this.arrErrors, error);
      }).finally(() => {
        this.projectsLoading = false;
        this.setIsLoading();
      });
    }
  }

  public isIndigenousVendor(vendor: Vendor) {
    return vendor.diversityDeclarations.indigenousImpact &&
      vendor.diversityDeclarations.indigenousImpact.values.filter((v) => v === "indigenousOwned").length > 0;
  }

  public getPayee(payeeId: string): ShortRecord|null {
    const vendor: ShortRecord|undefined = this.vendors ? this.vendors.find((v) => v.identifier === payeeId) : undefined;
    if (vendor !== undefined) {
      return vendor;
    } else {
      const ic: ShortRecord|undefined = this.ics ? this.ics.find((v) => v.identifier === payeeId) : undefined;
      if (ic !== undefined) {
        return ic;
      } else {
        return null;
      }
    }
  }

  public payees(): ShortRecord[]|null {
    return this.vendors;
  }

  public getRevenueType(expenseType: string): string {
    return expenseType.includes('-contribution') ? expenseType + '-revenue' : expenseType.replace('-expense', '-revenue');
  }

  public handleError(error: any) {
    handleError(this.arrErrors, error);
  }
}
